import React, { useEffect } from "react";
import { navigate } from "gatsby-link";
import { getDefaultLoginRoute } from "~/screens/login/useDefaultLoginRoutet";
import ExternalNothingToSee from "~/pages/external/404";
import { AppRoute } from "~/config/Routes";

function IndexPage() {
  useEffect(() => {
    if (isBlank) {
      if (process.env.NODE_ENV === "development") {
        navigate(AppRoute.ExternalLiveStreamNew.create(162, "16f81f6519d3"));
      }
      return;
    }
    navigate(getDefaultLoginRoute());
  }, []);
  if (isBlank) return <ExternalNothingToSee />;
  return null;
}

const isBlank = !!process.env.GATSBY_IS_BLANK_APP;

export default IndexPage;
